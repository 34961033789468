// import React, { useState, useEffect } from 'react';

// export default function Cart() {
//   const [cart, setCart] = useState(0);

//   useEffect(() => {
//     const loadScriptAsync = async (uri) => {
//       return new Promise((resolve,reject) => {
//         const s = document.createElement('script');
//         s.src = uri;
//         s.id = `ecwid-script`
//         s.setAttribute("data-cfasync", false);
//         s.async = true;
//         s.charset = "utf-8";
//         s.onload =() => {
//           resolve();
//         }
//         document.body.appendChild(s);
//       })
//     }

//     const scriptLoaded  =  loadScriptAsync(`https://app.shopsettings.com/script.js?24652016`);
    
//     scriptLoaded.then(function() {
//       window.Ecwid.OnAPILoaded.add(function() {
//         try {
//           const ownerId = window.Ecwid.getOwnerId();
//           window.Ecwid.Cart.get(function(cart) {
//             setCart(cart.productsQuantity)
//             localStorage.setItem("ec_current_cart", JSON.stringify(cart))
//           })
//           console.log("Id", ownerId)
//           window.Ecwid.init()
//           window.Ecwid._onBodyDone();
//         } catch(err) {
//           console.log(err)
//         }
//       });
//     })
//     return(() => {
//       if (typeof Ecwid !== "undefined") window.Ecwid.destroy()
//     })
//   },[])
//   return (
//     <div data-icon="cart" className="ec-cart-widget"></div>
//   )
// }


import React from "react"

export default function ShoppingBag({ classNames }) {
  return (
    <>
      <div data-icon="cart" className="ec-cart-widget"></div>
    </>
  )
}