import React from "react"
import SideBar from "react-sidebar"
// import { Motion, spring, presets } from "react-motion"
import Menu from "./menu"
import MenuBtn from "../../images/menu.svg"

class SideBarMenu extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			sidebarOpen: false,
			sidebarpullRight: true,
			toggle: false,
		}

		this.onSetSidebarOpen = this.onSetSidebarOpen.bind(this)
	}
	onSetSidebarOpen(state) {
		this.setState({ sidebarOpen: state.open, toggle: !this.state.toggle })
	}
	toggleMenu() {
		this.setState({
			sidebarOpen: !this.state.sidebarOpen,
			toggle: !this.state.toggle,
		})
	}

	render() {

		return (
			<SideBar
				sidebar={
					<Menu /> // Menu list
				}
				open={this.state.sidebarOpen}
				onSetOpen={this.onSetSidebarOpen}
				pullRight={this.state.sidebarpullRight}
				styles={{
					sidebar: {
						background: "#222",
						width: "265px",
						position: "fixed",
						zIndex: "999",
						textAlign: "left"
					},
					root: { position: "relative", overflow: "visible" },
					overlay: { backgroundColor: "rgba(0, 0, 0, 0.65)", zIndex: 2 },
					content: {
						position: "relative",
						overflow: "visible",
						cursor: "pointer",
					},
				}}
			>
				
				<button
					style={{
						border: "0 !important",
  					background: "none !important",
  					padding: "0",
  				}}
  				className="header-menu-btn"
          onClick={() => this.toggleMenu()}
        >
          <img src={MenuBtn} alt="Menu" width="30px" className="img-fluid" />
        </button>
			</SideBar>
		)
	}
}

export default SideBarMenu
