import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { useStaticQuery, graphql } from 'gatsby'
import get from 'lodash/get'
import Header from '../components/header'
import Footer from '../components/footer'
import 'bootstrap/dist/css/bootstrap.css'
import 'font-awesome/css/font-awesome.min.css'
import './index.css'
import BuckCover from '../images/buckwoodcover.jpg'

const Layout =({ head, children, pathname }) => {
  const data = useStaticQuery(graphql`
    query HeaderQuery {
      site {
        siteMetadata {
          title
          name
          siteUrl
          meta {
            keywords
            description
          }
        }
      }
    }
 `)

  const siteTitle = get(head, 'title') || get(data, 'site.siteMetadata.title')
  const siteName = get(head, 'name') || get(data, 'site.siteMetadata.name')
  const siteMetaDescription = get(head, 'meta.description') || get(data, 'site.siteMetadata.meta.description')
  const siteMetaKeywords = get(head, 'meta.keywords') || get(data, 'site.siteMetadata.meta.keywords')
  const siteURL = get(data, 'site.siteMetadata.siteUrl')
  const URL = pathname || get(head, 'catURL')

  return (
    <>
      <Helmet
        htmlAttributes={{
          lang: 'en'
        }}
        title={siteTitle + ' - ' + siteName}
        meta={[
          {
            name: 'description',
            content: siteMetaDescription,
          },
          {
            name: 'keywords',
            content: siteMetaKeywords,
          },
          {
            proper: 'author',
            content: 'Buck Woodcraft',
          },
          {
            property: 'og:url',
            content: siteURL + URL,
          },
          {
            property: 'og:type',
            content: 'article',
          },
          {
            property: 'og:title',
            content: siteTitle + ' - ' + siteName,
          },
          {
            property: 'og:description',
            content: siteMetaDescription,
          },
          {
            property: 'og:image',
            content: BuckCover,
          },
        ]}
      />
      <Header siteTitle={siteName} />
        <div>{children}</div>
      <Footer />
    </>
  )
}

export default Layout


