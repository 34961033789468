import React from "react"
import { Link } from 'gatsby'
import'./menu.css'

export default () => (
	<div className="navigation-list">
	<div className="navigation">
		<ul className="list-unstyled mainnav">
      <li>
        <Link to="/shop" title="Shop">
          Shop
        </Link>
      </li>
      <li>
        <Link to="/contact-us" title="Contact Us">
          Contact Us
        </Link>
      </li>
      <li>
      </li>
      <li>
        <Link to="/privacy-policy" title="Privacy Policy">
          Privacy Policy
        </Link>
      </li>
      <li>
        <Link
          to="/shipping-return-refund-and-cancellation-policy"
          title="Shipping, Return/Refund & Cancellation Policy"
        >
          Shipping, Return/Refund & Cancellation <br /> Policy
        </Link>
      </li>
      <li>
        <Link
          to="/rewards-points-information"
          title="Rewards Points Information"
        >
          Rewards Points Information
        </Link>
      </li>
      <li>
        <Link
          to="/useful-links-to-other-web-sites"
          title="Useful Links to Other Websites"
        >
          Useful Links to Other Websites
        </Link>
      </li>
    </ul>
    <div className="nav-contact">
      <a
        className="btn btn-primary blue-btn"
        role="button"
        href="tel:1-305-743-4090"
        title="telephone"
      >
        <i className="fa fa-phone" />
        305-743-4090
      </a>
      <a href="mailto:john@buckwoodcraft.com" title="email">
        <i className="fa fa-envelope-o" />
        john@buckwoodcraft.com
      </a>
    </div>
	</div>
	</div>
)
