import React from 'react'
import style from './index.module.css'
import { Link } from 'gatsby'
import LogoFooter from '../../images/buckwoodcraft-logo-footer.png'

const Footer = ({ siteTitle }) => (
  <footer>
    <div className={style.footer}>
      <div className="container">
         
        <div className="row">
          <div className="col-md-5">
            <img
              alt="logo footer"
              src={LogoFooter}
              className={`footer-logo img-fluid ${style.logo}`}
            />
            <div className="copyrightDesktop d-none d-md-block">
              <span>
                Copyright &copy; {new Date().getFullYear()} Buck Woodcraft, Inc.
                All rights reserved.
              </span>
            </div>
          </div>
          <div className="col-md-3">
            <h6>Quick Links</h6>
            <ul className={style.footerLinks}>
              <li>
                <Link to="/" title="Home">
                  Home
                </Link>
              </li>
              <li>
                <Link to="/shop" title="Shop">
                  Shop
                </Link>
              </li>
              <li>
                <Link to="/contact-us" title="Contact Us">
                  Contact Us
                </Link>
              </li>
              <li>
                <Link to="/privacy-policy" title="Privacy Policy">
                  Privacy Policy
                </Link>
              </li>
              <li>
                <Link
                  to="/shipping-return-refund-and-cancellation-policy"
                  title="Shipping, Return/Refund & Cancellation Policy"
                >
                  Shipping, Return/Refund & Cancellation Policy
                </Link>
              </li>
              <li>
                <Link
                  to="/rewards-points-information"
                  title="Rewards Points Information"
                >
                  Rewards Points Information
                </Link>
              </li>
              <li>
                <Link
                  to="/useful-links-to-other-web-sites"
                  title="Useful Links to Other Websites"
                >
                  Useful Links to Other Websites
                </Link>
              </li>
            </ul>
          </div>
          <div className="col-md-3 offset-md-1">
            <h6>Get in Touch</h6>
            <ul className={style.footerContact}>
              <li>Buck Woodcraft, Inc. 120 49th St. Marathon, FL 33050</li>
              <li>
                <a href="tel:1-305-743-4090" title="telephone">
                  305-743-4090
                </a>
              </li>
              <li>
                <a href="mailto:john@buckwoodcraft.com" title="email">
                  john@buckwoodcraft.com
                </a>
              </li>
            </ul>
          </div>
        </div>
      
      <div className={style.copyrightMobile}>
        <span className="d-block">
          Copyright &copy; {new Date().getFullYear()} Buck Woodcraft, Inc. All rights reserved.
        </span>
      </div>
      
      </div>
    </div>
  </footer>
)

export default Footer
