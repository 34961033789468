import React, {useEffect} from "react"
// import Sidebar from "react-sidebar"
import SideBarNav from "../sidebar/menuSidebar"
import Cart from "../ecwid/cart"
import { Link } from "gatsby"
import style from "./index.module.css"
import Logo from "../../images/buckwoodcraft-logo.png"

import useScript from "../../utils/useScript"



const Header=()=> {
  let script_status = useScript(
    `https://app.shopsettings.com/script.js?${
      process.env.ECWID_STORE_ID || "24652016"
    }`
  )

 useEffect(() => {
    if (script_status === "ready") {
      try {
        window.Ecwid.OnAPILoaded.add(function () {
          window.Ecwid.init()
        })
      } catch (error) {
        console.log(error)
      }
    }
  }, [script_status])

  useEffect(()=>{
      if (window) {
        const script = document.createElement("script")
        script.type = "text/javascript"
        script.src = `https://www.googletagmanager.com/gtag/js?id=UA-130170674-1`
        const headScript = document.getElementsByTagName("script")[0]
        headScript.parentNode.insertBefore(script, headScript)
      }
    })
    return (
      <div>
        <div className="ticker">
          <div className={style.ticker}>
            <div className="container">
              <div className="text-center d-lg-flex justify-content-lg-between">
                <div className=" d-lg-flex order-lg-2">
                  <a href="tel:1-305-743-4090" title="telephone" className="d-block mr-lg-3">
                    <span>
                      <i className="fa fa-phone" />
                      305-743-4090
                    </span>
                  </a>
                  <a
                    href="mailto:john@buckwoodcraft.com"
                    title="email"
                    className="d-block"
                  >
                    <span>
                      <i className="fa fa-envelope-o" />
                      john@buckwoodcraft.com
                    </span>
                  </a>
                </div>

                <div className="order-lg-1">
                  <span>
                    <i className="fa fa-map-marker" />
                    120 49th St. Marathon, FL 33050
                  </span>
                </div>
               
                  
                  
                
              </div>
            </div>
          </div>
          <div className={style.header}>
            <div className="container">
              
                
                  <div className="row">
                    <div
                      className={
                        style.headerSearch + " col-md-3 align-self-center"
                      }
                    >
                      {/*									<form action={`http://buckwoodcraft.webriqshops.com/${.form-search.value}`}>
										<div className="form-group d-inline-block">
											<input
												className="form-control form-search"
												type="search"
												required=""
												placeholder="Search"
											/>
										</div>
										<button
											className={style.circle + ' btn btn-primary'}
											type="submit"
										>
											<i className="fa fa-search" />
										</button>
									</form>*/}
                    </div>
                    <div className="col col-md-5 align-self-center text-center">
                      <Link to="/" title="Home">
                        <img
                          alt="logo"
                          src={Logo}
                          className="header-logo img-fluid"
                        />
                      </Link>
                    </div>
                    <div className="col col-md-3 align-self-center header-nav-btn">
                      <div className="navigation-menu float-right">
                        <ul className="text-right mb-0 d-flex list-unstyled align-items-center">
                          <li>
                            <Cart />
                          </li>
                          <li className="list-inline-item ml-4 nav_content">
                            <SideBarNav />
                          </li>
                        </ul>
                        <div className="navigation-list">
                          <div
                            className="navigation"
                            data-navigation-handle=".nav_handle"
                            data-navigation-content=".nav_content"
                          >
                            <ul className="list-unstyled d-none main-nav">
                              <li>
                                <Link to="/contact-us" title="Contact Us">
                                  Contact Us
                                </Link>
                              </li>
                              <li>
                                <Link
                                  to="/privacy-policy"
                                  title="Privacy Policy"
                                >
                                  Privacy Policy
                                </Link>
                              </li>
                              <li>
                                <Link
                                  to="/shipping-return-refund-and-cancellation-policy"
                                  title="Shipping, Return/Refund & Cancellation Policy"
                                >
                                  Shipping, Return/Refund & Cancellation Policy
                                </Link>
                              </li>
                              <li>
                                <Link
                                  to="/rewards-points-information"
                                  title="Rewards Points Information"
                                >
                                  Rewards Points Information
                                </Link>
                              </li>
                              <li>
                                <Link
                                  to="/useful-links-to-other-web-sites"
                                  title="Useful Links to Other Websites"
                                >
                                  Useful Links to Other Websites
                                </Link>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                
            </div>
          </div>
        </div>
      </div>
    )
}

export default Header
